import { Box, InputLabel, Modal, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

import Innovators from './../../../src/assets/images/innovators.png';
import EarlyAdoptors from './../../../src/assets/images/early_adoptors.png';
import EarlyMajority from './../../../src/assets/images/early_majority.png';
import LateMajority from './../../../src/assets/images/late_majority.png';
import { Link } from 'react-router-dom';

export const KbLanguages = styled(Box)(({ theme }) => ({
  padding: '0.75rem 2.5rem',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 8.125rem)',
  '& .MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 1rem 1rem 1rem',
    boxShadow: 'none',
    borderRadius: '0.375rem',
    border: 0,
    justifyContent: 'flex-start',
    height: '100%',
    position: 'relative',

    '&:after': {
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      position: 'absolute',
      zIndex: 9,
      content: `""`,
    },
  },

  '& .navigation-wrapper': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
    zIndex: 99,
    '& .navigation-inner:last-child': {
      borderBottom: 'none',
    },
    '& .noDataText': {
      textAlign: 'left',
      width: '100%',
      marginTop: '1rem',
      fontSize: '1rem',
    },
  },
  '& .noDataText': {
    textAlign: 'center',
    width: '100%',
    marginTop: '1rem',
  },

  '& .languageListWrapper': {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '2.125rem',
    backgroundColor: '#F8F8F8',
  },
  '& .languageListWrapperDownload': {
    width: '110rem',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
    backgroundColor: '#F8F8F8',
    overflow: 'visible',
    padding: '20px',
  },

  '& .card-list': {
    paddingInline: 0,
  },
  '& .languageListItem': {
    flexBasis: '25%',
    flexGrow: 0,
    '&:nth-of-type(1)': {
      '& .MuiPaper-root': {
        '&:after': {
          background: `url(${Innovators})`,
          backgroundSize: 'cover',
          height: '9.5rem',
        },
      },
    },
    '&:nth-of-type(2)': {
      '& .MuiPaper-root': {
        '&:after': {
          background: `url(${EarlyAdoptors})`,
          backgroundSize: 'cover',
          height: '20.625rem',
        },
      },
    },
    '&:nth-of-type(3)': {
      '& .MuiPaper-root': {
        '&:after': {
          background: `url(${EarlyMajority})`,
          backgroundSize: 'cover',
          height: '26.188rem',
        },
      },
    },
    '&:nth-of-type(4)': {
      '& .MuiPaper-root': {
        '&:after': {
          background: `url(${LateMajority})`,
          backgroundSize: 'cover',
          height: '24.688rem',
        },
      },
    },
  },
  '& .languageListItemDownload': {
    flexBasis: '25%',
    flexGrow: 0,
  },
  '& .languageListItemLegend': {
    display: 'flex',
    flexDirextion: 'column',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  '& .navigation-inner': {
    // padding: '0 1rem 1rem',
    marginBottom: '1rem',
    width: '100%',
    // borderBottom: '0.5px solid #BEBEBE',
    overflow: 'auto',
  },
  '& .subCategoryTitle': {
    fontWeight: '500',
    marginBottom: '1.25rem',
    fontSize: '1rem',
  },
  '& .downloadScreenshot': {
    color: '#0B93DF',
    fontSize: '1rem',
    marginLeft: '1rem',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export const FilterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0.5rem 0rem',
  justifyContent: 'right',
  alignItems: 'center',
}));

export const ModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '1rem',
  paddingBottom: '1rem',
  borderBottom: '1px solid #DDD',
  p: {
    fontSize: '1.125rem',
  },
}));

export const ModalBody = styled(Box)(({ theme }) => ({
  minHeight: '25rem',
  maxHeight: '70vh',
  overflow: 'auto',
  marginBottom: '0.5rem',
}));

export const KbSelectLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root[data-shrink='false']": {
    transform: 'translate(14px, 7px) scale(1)',
  },
  "&.MuiInputLabel-root[data-shrink='true']": {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
}));

export const KBSubCategory = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: '#fff',
  padding: '1.5rem 1rem',
  overflowY: 'auto',
  flexWrap:'wrap'
}));

export const KBSubCategoryCard = styled(Box)(({ theme }) => ({
  border: '1px solid #ddd',
  padding: '1.5rem 1rem 0 1rem',
  alignSelf: 'flex-start',
  borderRadius: '.25rem',
  display: 'flex',
  position: 'relative',
  flexGrow: 1,
  width: '100%',
  marginBottom:'1rem',
}));

export const KBSubCategoryName = styled(Typography)(({ theme }) => ({
  top: '-.85rem',
  backgroundColor: '#fff',
  paddingInline: '.5rem',
  paddingBlock: '.25rem',
  position: 'absolute',
  zIndex: 2,
  fontWeight: 500
}));

export const KBSubCategoryTechDigestName = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  fontWeight: 500,
  flexShrink: 0,
  color: '#343434',
  '&:hover':{
    color: theme.palette.primary.main
  }
}));

export const KBSubCategoryTechDigestImg = styled('img')(({ theme }) => ({
  marginRight: '.5rem',
  flexShrink: 0
}));

export const KBSubCategoryTechDigest = styled(Link)(({ theme }) => ({
  paddingBottom: '2rem',
  paddingRight: '2rem',
  cursor: 'pointer',
  color: theme.palette.primary.dark,
  textDecoration: 'none',
}));

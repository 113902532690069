import React, { useState } from 'react';
import { MarkdownComponent } from '../../Components/atoms/Markdown';
import { Box, Typography, Drawer } from '@mui/material';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Cross from '../../assets/images/cross.png';
import Chip from '@mui/material/Chip';

export enum ValueAddType {
  HARD_PROBLEMS = 'Hard Problem',
}

export const KbDrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  position: 'sticky',
  top: '0',
  zIndex: 10,
  backgroundColor: '#fff',
  borderBottom: '1px solid #D1D6DD',
  fontWeight: 500,
  fontSize: '1.25rem',
}));

export const KbDrawerBody = styled(Box)(({ theme }) => ({
  padding: '1rem',
}));

export const KbValueAddTags = styled(Chip)(({ theme }) => ({
  marginRight: '.5rem',
  marginBottom: '.5rem',
  textTransform: 'capitalize',
}));

export const KbValueAddLists = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginInline: '-1rem',
  paddingInline: 0,
  marginBlock: 0,
}));

export const KbValueAddTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '1rem',
}));

export const KbValueAddListItem = styled('li')(({ theme }) => ({
  width: '50%',
  listStyle: 'none',
  paddingInline: '1rem',
  display: 'flex',
}));

export const KbValueAddListItemContent = styled(Box)(({ theme }) => ({
  paddingInline: '1rem',
  marginBottom: '1rem',
  boxShadow: '0 0 8px -1px rgba(0,0,0,.25)',
  borderRadius: 8,
  padding: '1rem',
  flex: 1,
  cursor: 'pointer',
}));

export const KbValueAddDetails = styled(Box)(({ theme }) => ({
  marginRight: '1rem',
  '& span': {
    fontWeight: 500,
  },
}));

export const KbValueAddAuthors = styled(Box)(({ theme }) => ({
  marginInline: '.5rem',
  fontWeight: 500,
  '&:after': {
    content: '","',
  },
  '&:last-child:after': {
    content: '""',
  },
}));

export const ValueAdd = ({ data }: any) => {
  const ValueAddItem = ({ itemDetails }: any) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const handleClose = (e: Event) => {
      e.stopPropagation();
      setShowModal(false);
    };
    const openDrawer = () => {
      setShowModal(true);
    };

    const getValueAddType = (type: string) => {
      switch (type) {
        case 'HARD_PROBLEMS':
          return ValueAddType.HARD_PROBLEMS;
        default:
          return ValueAddType.HARD_PROBLEMS;
      }
    };

    return (
      <KbValueAddListItem onClick={openDrawer}>
        <KbValueAddListItemContent>
          <KbValueAddTitle variant='h4'>{itemDetails.customersValueAdd.title}</KbValueAddTitle>
          <Box display={'flex'} marginBottom={'.75rem'}>
            {itemDetails.customersValueAdd.teamId.teamName && <KbValueAddDetails>
              <label>Team: </label>
              <span>{itemDetails.customersValueAdd.teamId.teamName}</span>
            </KbValueAddDetails>}
            {itemDetails.customersValueAdd.type && <KbValueAddDetails>
              <label>Type: </label>
              <span>{getValueAddType(itemDetails.customersValueAdd.type)}</span>
            </KbValueAddDetails>}
            
          </Box>
          {itemDetails.customersValueAdd.ownerId && <Box display={'flex'} flexWrap={'wrap'} marginBottom={'.75rem'}>
            <label>Authors: </label>
            {itemDetails.customersValueAdd.ownerId?.map((owner: any, index: any) => (
              <KbValueAddAuthors key={index}>{owner?.name}</KbValueAddAuthors>
            ))}
          </Box>}
          
          {itemDetails.fileDetails.tags && <Box display={'flex'}>
            <Box component={'label'} marginRight={'.5rem'}>
              Domain:{' '}
            </Box>
            <Box display={'flex'} flexWrap={'wrap'} marginBottom={'-.5rem'}>
              {itemDetails.fileDetails.tags.split(',').map((tag: string, index: string) => (
                <KbValueAddTags key={index} label={tag} size='small' />
              ))}
            </Box>
          </Box>}
          
          <Drawer open={showModal} onClose={handleClose} anchor={'right'}>
            <Box role='presentation' sx={{ paddingBottom: '6.5rem' }}>
              <KbDrawerHeader>
                {itemDetails.customersValueAdd.title}
                <Button onClick={()=>handleClose}>
                  <img src={Cross} alt='Cross Icon' />
                </Button>
              </KbDrawerHeader>
              <KbDrawerBody>
                <MarkdownComponent>{itemDetails.fileDetails.summary}</MarkdownComponent>
              </KbDrawerBody>
            </Box>
          </Drawer>
        </KbValueAddListItemContent>
      </KbValueAddListItem>
    );
  };

  return (
    <>
      {data?.length ? (
        <KbValueAddLists>
          {data?.map((d: any, index: number) => {
            return <ValueAddItem key={index} itemDetails={d} />;
          })}
        </KbValueAddLists>
      ) : (
        <Typography variant='h3' className='noDataText'>
          Currently, no value add are associated with this technology
        </Typography>
      )}
    </>
  );
};

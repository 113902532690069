import axios, { AxiosInstance } from 'axios';

axios.defaults.withCredentials = true;
export const axiosInstance: AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_KBTECH_API_ENDPOINT,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    config.headers['Authorization'] = token ? `Bearer ${token}` : '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      alert();
    }
    return res;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(err);
  }
);

import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Icon from '../../Icons/icons';

interface HeaderWithIconProps {
  align?: 'left' | 'right' | 'center';
  title: string;
  variant: any;
  className?: string;
  isEndIcon?: boolean;
  isTotal?: boolean;
  total?: string;
}

const KbHeaderWithIcon = styled(Typography)({
  marginBottom: '2.375rem',
  fontWeight: 500,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingInline: '1rem'
});

export const HeaderWithIcon = ({
  align,
  title,
  variant,
  className,
  isEndIcon,
  isTotal,
  total,
}: HeaderWithIconProps) => {
  return (
    <KbHeaderWithIcon align={align} variant={variant} className={className}>
      <Box>
        {title} {isTotal && <span>({total})</span>}
      </Box>
      {/* {isEndIcon && <Icon kind='next' size={10} />} */}
    </KbHeaderWithIcon>
  );
};

import React, { createContext } from 'react';

export interface UpdatesContextType {
  newUpdates: any;
  setNewUpdates: React.Dispatch<React.SetStateAction<any>>;
  newUpdatesModal: boolean;
  setNewUpdatesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
export const UpdatesContext = createContext<UpdatesContextType>({
  newUpdates: [],
  setNewUpdates: () => {},
  newUpdatesModal: false,
  setNewUpdatesModal: () => {},
});

import {
  Card,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Drawer,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Grid,
} from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

import { HeaderWithIcon } from '../../Components/atoms/HeaderWithIcon';
import { Expertise, KbDrawerHeader, NavigationCard } from '../../Components/atoms/Card/navigationCard';

import { useParams, useLocation, useNavigate } from 'react-router-dom';

import {  SortDropdown } from '../../Components/atoms/Dropdown';

import {
  FilterWrapper,
  KBSubCategory,
  KBSubCategoryCard,
  KBSubCategoryName,
  KBSubCategoryTechDigest,
  KBSubCategoryTechDigestImg,
  KBSubCategoryTechDigestName,
  KbLanguages,
  KbSelectLabel,
  ModalBody,
} from './style';
import Cross from '../../assets/images/cross.png';
import { axiosInstance } from '../../Utils/AxiosInstance';
import { useContext } from 'react';
import { UpdatesContext } from '../../Context/NewUpdatesContext';
import { styleModal } from '../../Components/atoms/Card/style';
import { RecentlyUpdatedCard } from '../../Containers/RecentlyUploaded';
import { ModalHeader } from './style';
import Icon from '../../Components/Icons/icons';
import { CircularLoader } from '../../Components/atoms/CircularLoader';
import { toJpeg } from 'html-to-image';
import html2canvas from 'html2canvas';
const Languages = () => {
  const { category } = useParams();
  const navigate = useNavigate();

  const [originalData, setOriginalData] = useState<any>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [recentData, setRecentData] = useState<any>([]);
  const [expertiseLevel, setExpertiseLevel] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [popularSubCategory, setPopularSubCategory] = useState<any>([]);
  const [legendObj, setLegendObj] = useState<any>({});

  const [subsCategoriesFilterlist, setSubsCategoriesFilterlist] = useState<any>([]);
  const [popularity, setPopularity] = useState<any>(false);
  const { newUpdates, setNewUpdates, newUpdatesModal, setNewUpdatesModal } = useContext(UpdatesContext);
  const [viewAllUpdates, setViewAllUpdates] = useState<any>();
  const [groupBy, setGroupBy] = useState<string>('Maturity');
  const [techDigestsPerSubCategory, setTechDigestsPerSubCategory] = useState([]);

  const [chartLoaded, setChartLoaded] = useState(false);

  const maturityLevelsData: any = [
    { type: 'Innovators', data: [] },
    { type: 'Early Adoptors', data: [] },
    { type: 'Early Majority', data: [] },
    { type: 'Late Majority', data: [] },
  ];

  const formatMaturityLevelsData = (list: any) => {
    maturityLevelsData?.map((ml: any, i: number) => {
      const arr = list?.filter((item: any) => item?.attributes?.maturity_level?.data?.attributes?.name === ml.type);
      maturityLevelsData[i].data = [...arr];
    });
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`tech-categories?filters[slug][$eqi]=${category}&&populate=deep,3`);
      if (response?.status === 200 && response?.data?.data) {
        setPageLoading(false);
        setError(false);
        let subcat = [...response?.data?.data]?.map((item: any) => {
          return item?.attributes?.tech_sub_categories;
        });

        setSubCategories(subcat?.flatMap((cate: any) => cate?.data));
        setOriginalData(response?.data?.data);

        let subCategoriesSorted: any = {};
        let popularSubCategory: string[] = [];

        response?.data?.data.forEach((category: any) => {
          category?.attributes?.tech_digests?.data.forEach((techDigest: any) => {
            let subCategoryList = techDigest?.attributes?.tech_sub_categories.data.map(
              (data: any) => data.attributes.name
            );
            subCategoryList = subCategoryList && subCategoryList.length > 0 ? subCategoryList : ['Others'];

            subCategoryList.forEach((category: string) => {
              if (subCategoriesSorted[category]) {
                subCategoriesSorted[category] += 1;
              } else {
                subCategoriesSorted[category] = 1;
              }
            });
          });
        });

        if (subCategoriesSorted) {
          let sortedEntries = Object.entries(subCategoriesSorted).sort((a: any, b: any) => b[1] - a[1]);
          let sortedObj = Object.fromEntries(sortedEntries);
          popularSubCategory = Object.keys(sortedObj)
            .filter((key) => key != 'Others')
            .slice(0, 4);
          let chartObj: any = {};
          sortedEntries.forEach((entry) => {
            if (popularSubCategory.includes(entry[0])) {
              if (chartObj[entry[0]]) {
                chartObj[entry[0]] = chartObj[entry[0]] + entry[1];
              } else {
                chartObj[entry[0]] = entry[1];
              }
            } else {
              if (chartObj['Others']) {
                chartObj['Others'] = chartObj['Others'] + entry[1];
              } else {
                chartObj['Others'] = entry[1];
              }
            }
          });

          let otherCount = chartObj['Others'];
          delete chartObj['Others'];
          let finalObj = chartObj;
          if (otherCount) {
            finalObj['Others'] = otherCount;
          }

          setLegendObj(finalObj);
          setPopularSubCategory(popularSubCategory);
        }

        response?.data?.data.map((category: any, i: any) => {
          maturityLevelsData?.map((maturityLevel: any, j: any) => {
            const dd = category?.attributes?.tech_digests?.data?.filter((td: any) => {
              return td?.attributes?.maturity_level?.data?.attributes?.name === maturityLevel?.type;
            });
            let groupedData = [...dd].reduce((acc: any, item: any) => {
              let subCategoryItems = item?.attributes?.tech_sub_categories.data.map(
                (data: any) => data.attributes.name
              );

              let subCategory = subCategoryItems && subCategoryItems.length > 0 ? subCategoryItems[0] : 'NA';

              if (acc[subCategory]) {
                acc[subCategory].push(item);
              } else {
                acc[subCategory] = [item];
              }

              return acc;
            }, {});

            let orderMap: any = {};
            popularSubCategory.forEach((item, index) => {
              orderMap[item] = index;
            });

            let foundKeys: string[] = [];
            let notFoundKeys: string[] = [];
            let groupedDataKeys: string[] = [];

            Object.keys(groupedData).forEach((key) => {
              if (popularSubCategory.includes(key)) {
                foundKeys.push(key);
              } else {
                notFoundKeys.push(key);
              }
            });

            foundKeys = foundKeys.sort((a, b) => orderMap[a] - orderMap[b]);

            groupedDataKeys = foundKeys;
            groupedDataKeys = popularSubCategory ? groupedDataKeys.concat(notFoundKeys) : Object.keys(groupedData);

            let sortedGroupData: any = {};
            groupedDataKeys.forEach((key) => {
              sortedGroupData[key] = groupedData[key];
            });

            maturityLevelsData[j].data = [...Object.values(sortedGroupData)].flat();
          });
        });

        setNewUpdates({
          count: response?.data?.data?.[0]?.attributes?.recent_updates?.data?.length,
          data: response?.data?.data?.[0]?.attributes?.recent_updates?.data,
        });

        const techCategoryRecentUpdates: any = response?.data?.data?.[0]?.attributes?.recent_updates?.data;
        const techDigestRecentUpdates: any = response?.data?.data?.[0]?.attributes?.tech_digests?.data?.filter(
          (t: any) => t?.attributes?.recent_updates?.data?.length
        );
        const tdRc: any = [];
        techDigestRecentUpdates?.map((rc: any) => {
          tdRc.push(...rc?.attributes?.recent_updates?.data);
        });

        setViewAllUpdates({
          count: [...techCategoryRecentUpdates, ...tdRc].length,
          data: [...techCategoryRecentUpdates, ...tdRc],
        });

        setRecentData(maturityLevelsData);
      }
    } catch (err) {
      setPageLoading(false);
      setError(true);
    }
    // const response = await axiosInstance.get(`tech-categories?filters[slug][$eqi]=${category}&&populate=deep,3`);
  };

  const getColour = (item: any) => {
    let popularity = -1;
    item.forEach((i: any) => {
      let index = popularSubCategory.indexOf(i);
      if (index > popularity) {
        popularity = index;
      }
    });
    switch (popularity) {
      case 0:
        return '#FBE9E7';
      case 1:
        return '#FFFDE7';
      case 2:
        return '#E8F5E9';
      case 3:
        return '#E1F5FE';
      default:
        return '#FFFFFF';
    }
  };

  const getLabelColour = (label: number) => {
    let index = popularSubCategory.indexOf(label);
    switch (index) {
      case 0:
        return '#FFCCBC';
      case 1:
        return '#FFF9C4';
      case 2:
        return '#C8E6C9';
      case 3:
        return '#BBDEFB';
      default:
        return '#BDBDBD';
    }
  };

  useEffect(() => {
    fetchData();
  }, [category]);

  useEffect(() => {
    // get the data
    const allData = [...originalData]?.flatMap((item: any) => {
      return item?.attributes?.tech_digests?.data;
    });

    let explvl: number = expertiseLevel?.length;
    let sublvl: number = subsCategoriesFilterlist?.length;
    let pop: boolean = popularity;

    const getExpertiseData = (list: any[]) => {
      return list.filter((item: any) => {
        return (
          expertiseLevel.includes(item?.attributes?.expertise_level) ||
          expertiseLevel.includes(String(item?.attributes?.learning))
        );
      });
    };

    const getSubCateData = (list: any[]) => {
      return list?.filter((item: any) =>
        item?.attributes?.tech_sub_categories?.data?.some((dt: any) =>
          subsCategoriesFilterlist.includes(dt?.attributes?.name)
        )
      );
    };

    const getPoplarityData = (list: any) => {
      return list?.filter((item: any) => item?.attributes?.popularity);
    };

    const explevel = explvl ? getExpertiseData(allData) : allData;
    const sublevel = sublvl ? getSubCateData(explevel) : explevel;
    const poplevel = pop ? getPoplarityData(sublevel) : sublevel;

    formatMaturityLevelsData(poplevel);

    setRecentData(maturityLevelsData);
  }, [expertiseLevel, subsCategoriesFilterlist, popularity]);

  const handleViewAllUpdates = () => {
    setNewUpdates({ count: viewAllUpdates?.count, data: [...viewAllUpdates?.data] });
    setNewUpdatesModal(true);
  };

  // update in the list (sorting data)
  const sorting = (type: boolean, key: string): void => {
    const list: any = [...recentData].map((data: any) => {
      const datalists = type
        ? data.data.sort((a: any, b: any) => a?.attributes?.[key]?.localeCompare(b?.attributes?.name))
        : data.data.sort((a: any, b: any) => b?.attributes?.[key]?.localeCompare(a?.attributes?.name));
      return { type: data.type, data: datalists };
    });
    setRecentData(list);
  };
  const selectExpertiseLevel = (exp: Array<{ title: string; value: string }>) => {
    if (exp?.length) {
      const arr: any = [];
      exp.map((e: any) => {
        if (e !== 'false' || e !== 'true') {
          const temp = e?.split(',');
          if (temp[0] !== undefined && temp[0] !== null) {
            arr.push(temp[0]);
          }
          if (temp[1] !== undefined && temp[1] !== null) {
            arr.push(temp[1]);
          }
        } else {
          arr.push(e === 'true' ? true : false);
        }
      });
      setExpertiseLevel(arr);
    } else {
      setExpertiseLevel(exp);
    }
  };

  const handleSubsCategories = (subs: any) => {
    if (subs?.length) {
      setSubsCategoriesFilterlist(subs);
    } else {
      setSubsCategoriesFilterlist(subs);
    }
  };
  const handlePopularity = (pop: boolean) => {
    pop ? setPopularity(pop) : setPopularity(pop);
  };
  const clearExpertiseLevel = () => {
    fetchData();
  };

  const handleOpen = () => setNewUpdatesModal(true);
  const handleClose = () => setNewUpdatesModal(false);

  useEffect(() => {
    return () => {
      setNewUpdates([]);
      setNewUpdatesModal(false);
    };
  }, []);

  const componentRef: any = useRef(null);
  const location: any = useLocation();
  const [download, setDownload] = useState(false);
  const [isChart, setIsChart] = useState(false);

  useEffect(() => {
    if (download && (chartLoaded || !isChart)) {
      if (componentRef.current === null) {
        return;
      }
      const scale = 5;
      html2canvas(componentRef.current, {
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
        scale: scale,
        width: componentRef.current.width,
        height: componentRef.current.scrollHeight,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.95);
        const link = document.createElement('a');
        link.href = imgData;
        link.download = location.pathname ? location.pathname + '.jpeg' : 'Screenshot.jpeg';
        document.body.appendChild(link);

        link.click();
        setDownload(false);
        setChartLoaded(false);
        document.body.removeChild(link);
      });
    }
  }, [download, chartLoaded]);

  const downloadFile = async () => {
    setDownload(true);
  };

  const handleGroupBy = (e: SelectChangeEvent) => {
    setGroupBy(e.target.value);
    if (e.target.value === 'Sub Category') {
      const techWithSubCategories = originalData[0].attributes.tech_digests.data?.filter(
        (tech: any) => tech.attributes.tech_sub_categories.data.length > 0
      );
      const techDigestWithSubCategory = subCategories.map((subCategory: any) => {
        return {
          subCategory: subCategory.attributes.name,
          techDigests: techWithSubCategories.filter(
            (tech: any) => tech.attributes.tech_sub_categories.data[0].attributes.name === subCategory.attributes.name
          ),
        };
      });
      setTechDigestsPerSubCategory(techDigestWithSubCategory);
    }
  };

  return (
    <KbLanguages>
      {pageLoading ? (
        <CircularLoader />
      ) : error ? (
        <Typography variant='h3' className='noDataText'>
          Something went wrong.
        </Typography>
      ) : (
        <>
          <FilterWrapper>
            {viewAllUpdates?.count ? (
              <Typography onClick={handleViewAllUpdates} sx={{ mr: 0.5, cursor: 'pointer' }}>
                View All Updates
              </Typography>
            ) : null}

            <FormControl sx={{ minWidth: 150, ml: '1rem' }}>
              <KbSelectLabel id='controlled-open-select-label'>Group By</KbSelectLabel>
              <Select
                labelId='controlled-open-select-label'
                // id='controlled-open-select'
                name='controlled-open-select'
                value={groupBy}
                sx={{ backgroundColor: '#fff' }}
                label='Sub Category'
                onChange={handleGroupBy}
              >
                <MenuItem disabled={subCategories?.length === 0} value={'Sub Category'}>Sub Category({subCategories.length})</MenuItem>
                <MenuItem value={'Maturity'}>Maturity</MenuItem>
              </Select>
            </FormControl>
            <SortDropdown
              label='Filter by'
              icon='filter'
              type='filter'
              expertiseLevel={expertiseLevel}
              selectExpertiseLevel={selectExpertiseLevel}
              clearExpertiseLevel={clearExpertiseLevel}
              subCategories={subCategories}
              handleSubsCategories={handleSubsCategories}
              handlePopularity={handlePopularity}
            />
            <SortDropdown label='Sort By' type='sort' sorting={sorting} icon='sort' />
            <Box style={{ marginLeft: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChart}
                    onChange={(e: any) => {
                      setIsChart(e.target.checked);
                    }}
                    name={'isChart'}
                    color='primary'
                    disabled={popularSubCategory?.length == 0}
                  />
                }
                label={'Chart'}
              />
            </Box>
            <Box style={{ cursor: 'pointer' }}>
              <Icon kind={'download'} onClick={downloadFile} />
            </Box>
          </FilterWrapper>

          {groupBy === 'Maturity' && (
            <Box
              className={!download ? 'languageListWrapper' : 'languageListWrapperDownload'}
              ref={componentRef}
              id='capture'
            >
              {recentData && recentData?.length && (
                <>
                  {recentData?.map((item: any,index :string) => {
                    return (
                      <Box
                        key={index}
                        className={!download || !isChart ? 'languageListItem' : 'languageListItemDownload'}
                      >
                        <Card className='card-list'>
                          <HeaderWithIcon title={item?.type} variant='h4' isEndIcon={true} />
                          <Box className='navigation-inner'>
                            {item?.data?.map((content: any) => {
                              const { name } = content?.attributes;
                              let getSlug: any;
                              let datalist = content?.attributes?.tech_categories?.data;
                              getSlug = datalist?.filter((d: any) => d?.attributes?.slug === category);

                              return (
                                <>
                                  <NavigationCard
                                    // src={content?.attributes?.src}
                                    src={content?.attributes?.icon?.data?.attributes?.url}
                                    name={content?.attributes?.name}
                                    alt={content?.attributes?.alt}
                                    isLearningIcon={content?.attributes?.learning}
                                    key={content?.attributes?.name}
                                    slug={content?.attributes?.slug}
                                    hasOurExpertise={content?.attributes?.our_expertise.data}
                                    hasUpdate={content?.attributes?.hasUpdate}
                                    link={`/${getSlug[0]?.attributes.slug}/${encodeURIComponent(
                                      content?.attributes?.name
                                    )}`}
                                    expertise_level={content?.attributes?.expertise_level}
                                    description={content.attributes?.description}
                                    recent_updates={content.attributes?.recent_updates}
                                    popularity={content?.attributes?.popularity}
                                    download={download}
                                    subCategoryColor={getColour(
                                      content?.attributes?.tech_sub_categories.data.map(
                                        (data: any) => data.attributes.name
                                      )
                                    )}
                                    hasSubCategory={isChart}
                                  />
                                </>
                              );
                            })}
                          </Box>
                        </Card>
                      </Box>
                    );
                  })}
                  {download && isChart && popularSubCategory?.length > 0 && (
                    <Box className={'languageListItemLegend'}>
                      <Chart
                        type='pie'
                        width={150}
                        height={500}
                        series={[...Object.values(legendObj)] as number[]}
                        options={{
                          labels: Object.keys(legendObj),
                          legend: {
                            show: true,
                            position: 'bottom',
                            floating: false,
                            showForSingleSeries: true,
                            horizontalAlign: 'left',
                            fontFamily: 'Helvetica, Arial',
                            fontWeight: 400,
                            fontSize: '12px',
                          },
                          dataLabels: {
                            enabled: true,
                            formatter: (val: any, opts): string => {
                              return Math.round(val) + '%';
                            },
                            dropShadow: {
                              enabled: true,
                              top: 1,
                              left: 1,
                              blur: 1,
                              color: '#000',
                              opacity: 0,
                            },

                            style: {
                              fontSize: '10px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 'bold',
                              colors: ['black'],
                            },
                          },
                          chart: {
                            events: {
                              animationEnd: function (chartContext, config) {
                                setChartLoaded(true);
                              },
                            },
                          },
                          colors: Object.keys(legendObj).map((level: any) => getLabelColour(level)),
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}

          {groupBy === 'Sub Category' && (
            <KBSubCategory>
            <Grid container spacing={4}>
              {techDigestsPerSubCategory.map((tech: any) => {
                return (
                  <Grid item xs={4} display={'flex'} flexDirection={'column'}>
                  <KBSubCategoryCard>
                      <KBSubCategoryName>{tech.subCategory}</KBSubCategoryName>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        alignSelf={'flex-start'}
                        flexWrap={'wrap'}
                      >
                        {tech.techDigests.map((techDigest: any) => {
                          console.log('techDigest',techDigest)
                          return (
                            <KBSubCategoryTechDigest to={`/${techDigest.attributes.tech_categories.data[0].attributes.slug}/${encodeURIComponent(
                              techDigest.attributes.name
                            )}`}>
                              <Box display={'flex'} marginBottom={1}>
                              {techDigest.attributes.icon.data?.attributes.url && (
                                <KBSubCategoryTechDigestImg
                                  width={20}
                                  src={techDigest.attributes.icon.data?.attributes.url}
                                  alt={techDigest.attributes.icon.data?.attributes.name}
                                />
                              )}
                              <KBSubCategoryTechDigestName>{techDigest.attributes.name}</KBSubCategoryTechDigestName>
                              </Box>
                              
                              <Expertise expertise_level={techDigest.attributes.expertise_level} />
                            </KBSubCategoryTechDigest>
                          );
                        })}
                      </Box>
                    </KBSubCategoryCard>
                  </Grid>
                );
              })}
            </Grid>
            </KBSubCategory>
          )}

          <Drawer open={newUpdatesModal} onClose={handleClose} anchor={'right'}>
            <Box role='presentation' sx={{ paddingBottom: '6.5rem' }}>
              <KbDrawerHeader>
                {/* <NavigationImg src={process.env.REACT_APP_KBTECH_STRAPI_BASE_URL + src} alt={alt} /> */}

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography component={'p'} textTransform={'capitalize'}>
                    {category}
                  </Typography>
                </div>
                <Button onClick={handleClose}>
                  <img src={Cross} alt='Cross Icon' />
                </Button>
              </KbDrawerHeader>
              <RecentlyUpdatedCard recentUpdateData={newUpdates?.data} />
            </Box>
          </Drawer>
        </>
      )}
    </KbLanguages>
  );
};

export default Languages;

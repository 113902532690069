import { Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

interface ChipProps {
  impact?: string;
  className?: string;
}

const KbChip = styled(Typography)(({ theme }) => ({
  padding: '0.25rem 0.625rem',
  display: 'inline-flex',
  lineHeight: 1,
  borderRadius: '0.25rem',
}));

export const Chip = ({ impact, className }: ChipProps) => {
  return (
    <>
      {impact ? (
        <KbChip
          className={className}
          variant='overline'
          color={
            impact === 'High Impact' || impact === 'high impact'
              ? '#FFF'
              : impact === 'Medium Impact' || impact === 'medium impact'
              ? '#FFF'
              : impact === 'Low Impact' || impact === 'low impact'
              ? '#FFF'
              : '#000'
          }
          bgcolor={
            impact === 'High Impact' || impact === 'high impact'
              ? '#10B857'
              : impact === 'Medium Impact' || impact === 'medium impact'
              ? '#F0AD56'
              : impact === 'Low Impact' || impact === 'low impact'
              ? '#2bbdd1'
              : '#EAF5FF'
          }
        >
          {impact}
        </KbChip>
      ) : (
        ''
      )}
    </>
  );
};

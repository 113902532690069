import { Box, Container } from '@mui/material';
import { Categories } from '../../Components/molecules/Categories';
import { Filters } from '../../Components/molecules/Filters';
import { RecentlyUploadedSection } from '../../Components/molecules/RecentlyUploadedSection';
import { TechDigests } from '../../Components/molecules/TechDigest';

const Dashboard = () => {
  return (
    <Box>
      <Filters />
      <Container>
        <Categories />
        {/* <TechDigests />
        <RecentlyUploadedSection /> */}
      </Container>
    </Box>
  );
};

export default Dashboard;

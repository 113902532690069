import React from 'react';
import { styled } from '@mui/material/styles';
import Icon from '../../Components/Icons/icons';
import { Box, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

interface ITeam {
  digestId: number
  customerName: string
  link: string
  startDate: string;
  teamSize: number
}


interface ITeams {
  data: Array<ITeam>;
}

const KbTeamsCardWrapper = styled('ul')(({theme})=>({
  display: 'flex',
  flexWrap: 'wrap',
  marginInline:'-1rem',
  paddingInline: 0,
  marginBlock: 0
}))

const KbTeamsCard = styled('li')(({ theme }) => ({
  width:'25%',
  listStyle:'none',
  paddingInline: '1rem',
}));

const KbTeamsCardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: '1rem',
  boxShadow: '0 0 8px -1px rgba(0,0,0,.25)',
  borderRadius: 8,
  padding:'1rem',

  '& img, & svg': {
    marginRight: 10,
    flexShrink: 0
  },
}));

const KbTeamsCustomerName= styled('span')(({ theme }) => ({
  fontSize: '1.25rem',
    fontWeight: 500,
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    display:'block',
    overflow:'hidden',
    width:'fit-content'
}));

const KbTeamsLabel= styled('span')(({ theme }) => ({
  fontSize: '.875rem',
}));

const KbTeamsInfo= styled('span')(({ theme }) => ({
  fontSize: '.875rem',
}));

export const Teams = ({ data }: ITeams): JSX.Element => {
  let oldestTeamStartDate: Date = new Date();
  data.forEach(d=>{
    const tempDate = oldestTeamStartDate
    const currentDate = new Date(d.startDate)
    if(tempDate < currentDate){
      oldestTeamStartDate = tempDate
    }else{
      oldestTeamStartDate = currentDate
    }
  })

  return (
    <>
      {data?.length ? (
        <>
        <Box marginBottom={3} display={'flex'} marginLeft={'auto'}>
          <Box marginRight={2}>
            Total Teams: <strong>{data.length}</strong>
          </Box>
          <Box>
            Working Since: <strong>{oldestTeamStartDate.toISOString().slice(0, 10)}</strong>
          </Box>
        </Box>
        
        <KbTeamsCardWrapper>
          {data?.map((d: ITeam) => (
            <KbTeamsCard key={d.digestId}>
              <KbTeamsCardContent>
                
                {d.link ? (
                  <img width={40} height={40} src={d.link} alt={d.customerName} />
                ) : (
                  <Icon size={40} kind='default-logo' />
                )}
                <Box overflow={'hidden'}>
                  <Tooltip title={d.customerName} arrow placement='top'>
                <KbTeamsCustomerName>{d.customerName}</KbTeamsCustomerName></Tooltip>
                
                <div>
                <div>
                  <KbTeamsLabel>Team Size : </KbTeamsLabel>
                  <KbTeamsInfo>
                  {d.teamSize}
                  </KbTeamsInfo>
                </div>
                <div>
                  <KbTeamsLabel>Working Since :  </KbTeamsLabel>
                  <KbTeamsInfo>
                    {d.startDate}
                  </KbTeamsInfo>
                </div>
                </div>

                </Box>
                
              </KbTeamsCardContent>
            </KbTeamsCard>
          ))}
        </KbTeamsCardWrapper>
        </>
      ) : (
        <Typography variant='h3' className='noDataText'>
          Currently, no teams are associated with this technology
        </Typography>
      )}
    </>
  );
};

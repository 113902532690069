import { createTheme } from '@mui/material/styles';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

export const theme = createTheme({
  palette: {
    background: {
      default: '#F8F8F8',
    },
    primary: {
      main: '#0B93DF',
      light: '#00A3FF',
      dark: '#343434',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#9DD068',
      main: '#219653',
    },
    error: {
      light: '#EB5757',
      main: '#C30A07',
    },
    warning: {
      light: '#FEBF1C',
      main: '#FF9900',
    },
    text: {
      primary: '#343434',
      secondary: '#666666',
    },
    grey: {
      50: '#C4C4C4',
      100: '#A5A5A5',
      200: '#898989',
      300: '#3F3F3F',
      400: '#4556AF',
      500: '#7B7B7B',
      600: '#222222',
    },
    divider: '#A39F9F',
  },
  typography: {
    h1: {
      fontSize: '2.375rem',
      fontWeight: 'bold',
      lineHeight: 1.19,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.14,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '500',
      lineHeight: 1.14,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.14,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.12,
      fontWeight: 'normal',
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: 1.16,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      lineheight: 1,
    },
    overline: {
      fontSize: '.75rem',
      fontWeight: 'normal',
      lineHeight: 1.16,
    },
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          paddingBlock: '1.75rem',
          paddingInline: '2rem',
          boxShadow: 'none',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DEDEED ',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0B93DF',
          },
        },
        notchedOutline: {
          border: '1px solid #DEDEED',
        },
      },
    },
    MuiTooltip:{
      styleOverrides:{
        popper:{
          '&[data-popper-placement*="top"] .MuiTooltip-tooltip':{
            marginBottom: '6px !important'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          color: '#A5A5A5',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#00A3FF',
          fontSize: '0.875rem',
          lineHeight: 1,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #D9D9D9',
          borderRadius: '0.188rem',
          boxShadow: '-1px 1px 4px rgba(0, 0, 0, 0.15)',
          minHeight: '8.188rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },

    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          marginLeft: '3.125rem',
          fontSize: '1.25rem',
          '& a': {
            color: '#343434',
            textDecoration: 'none',
            textTransform: 'capitalize',
            '&.inactive': {
              fontWeight: '500',
              pointerEvents: 'none',
            },
          },
        },
        separator: {
          margin: '0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#0B93DF',
          },
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '1rem 2.2rem 1.5rem',
          '& .MuiTypography-body1': {
            marginTop: '1.375rem',
            '&:first-of-type': {
              marginTop: 0,
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '2.375rem',
          paddingLeft: '1.2rem',
        },
        content: {
          '& .count': {
            marginLeft: '0.2rem',
          },
          '&.Mui-expanded': {
            '&:after': {
              content: '""',
              width: '0.25rem',
              height: '100%',
              background: '#0B93DF',
              left: 0,
              display: 'flex',
              position: 'absolute',
              borderRadius: '0.375rem',
              top: 0,
            },
            '& .MuiTypography-body1': {
              fontWeight: '500',
            },
          },
        },
      },
    },
    MuiTabs:{
      styleOverrides:{
        flexContainer:{
          borderBottom:"0.219rem solid #E0E8F4",
        }
      }
    },
    MuiTab:{
      styleOverrides:{
        root:{
          opacity:1,
          fontWeight:"400",
          textTransform:"none",
          paddingBottom:"0px",
          paddingTop:"20px",
          color:"#343434",
          "&.Mui-selected":{
            fontWeight:"500"
          }
        },
       
      }
    },
  
    MuiBackdrop:{
      styleOverrides:{
        root:{
          '&.MuiModal-backdrop':{
            top:"6rem"
          }
        }
      }
    },
    MuiPaper:{
      styleOverrides:{
        root:{
          '&.MuiDrawer-paper':{
            boxShadow:"none",
            top:"5.9rem",
            width:"44.5rem"
          }
        }
      }
    },
    MuiDivider:{
      styleOverrides:{
        root:{
          borderColor:"#D1D6DD"
        }
      }
    },
    MuiSelect:{
      styleOverrides:{
        select:{
          padding:"7px 14px"
        }
      }
    }
  },
});

import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const KbCircularLoader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CircularLoader = () => {
  return (
    <KbCircularLoader>
      <CircularProgress />
    </KbCircularLoader>
  );
};

import React, { useContext } from 'react';
import { Header } from '../../Components/Header';
import { UserContextType, UserContext } from '../../Context/UserContext';
import RouteConfig from '../../Routes/RouteConfig';

const RootApp = () => {
  const { user } = useContext<UserContextType>(UserContext);
  return (
    <>
      {user && <Header isBreadcrumb={true} isSearch={true} />}
      <RouteConfig />
    </>
  );
};

export default RootApp;

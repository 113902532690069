import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { MarkdownComponent } from '../../Components/atoms/Markdown';
import { RightSideContent } from '../../Components/molecules/RightSideBar';
import { LeftSideBarHeader } from '../../Components/molecules/LeftSideBar';
import Individual from '../../assets/images/individual.png';

import { Banner } from './banner';
interface OurExpertiseInterface {
  data: any;
  src: string;
  tabName?: string;
  alt?: string;
  title?: string;
  technologyAdoption?: string;
}

export const OurExpertise = ({ data, src, alt, title, tabName, technologyAdoption }: OurExpertiseInterface) => {
  return (
    <>
      <RightSideContent>
        {data?.data ? (
          <>
            <LeftSideBarHeader
              title={title!}
              technologyAdoption={technologyAdoption!}
              date={null!}
              src={src!}
              alt={alt!}
            />
            {data?.data?.attributes?.team && (
              <Box sx={{ m: '1.25rem 0 0 0' }}>
                <Typography variant={'h3'}>{data?.data?.attributes?.team}</Typography>
              </Box>
            )}
            <Banner
              date={data?.data?.attributes?.updatedAt}
              manDaysOfEffort={data?.data?.attributes?.man_days_of_effort}
              totalExperience={data?.data?.attributes?.total_experience}
            />

            {data?.data?.attributes?.stats && (
              <div style={{ paddingBottom: '1rem' }}>
                <Box component={'h3'} marginBottom={'.25rem'} fontSize={'1.25rem'}>
                  Stats
                </Box>

                <MarkdownComponent>{data?.data?.attributes?.stats}</MarkdownComponent>
              </div>
            )}

            <div style={{ paddingBottom: '1rem' }}>
              <Box component={'h3'} marginBottom={'.25rem'} fontSize={'1.25rem'}>
                Features Heavily Used
              </Box>

              <MarkdownComponent>{data?.data?.attributes?.features_heavily_used}</MarkdownComponent>
            </div>
            <div style={{ paddingBottom: '1rem' }}>
              <Box component={'h3'} marginBottom={'.25rem'} fontSize={'1.25rem'}>
                Challenges Faced
              </Box>

              <MarkdownComponent>{data?.data?.attributes?.challenges_faced}</MarkdownComponent>
            </div>
            <div>
              <Box component={'h3'} marginBottom={'1.75rem'} fontSize={'1.25rem'}>
                Mistakes Made
              </Box>

              <MarkdownComponent>{data?.data?.attributes?.mistakes_made}</MarkdownComponent>
            </div>
          </>
        ) : (
          <Typography variant='h3' className='noDataText'>
            Sorry, we couldn't find any results
          </Typography>
        )}
      </RightSideContent>
    </>
  );
};

import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import DefaultImg from '../../../assets/images/default-image.jpeg';

interface TechTalksProps {
  url: string;
  title: string;
}

const KbTechTalksCard = styled(Card)({
  boxShadow: 'none',
  flexDirection: 'column',
  border: 'none',
  marginBottom: '1.5rem',
  backgroundColor: 'transparent',
  '& .MuiTypography-body1': {
    marginTop: '1rem',
    fontWeight: '500',
  },
  '& .iframContainer': {
    width: '100%',
    overflow: 'hidden',
    paddingTop: '56.25%',
    position: 'relative',
    '& .ifram': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: ' 100%',
      height: '100%',
      border: '1px solid #DEDEED',
      borderRadius: '0.375rem',
    },
  },
  '& .defaultImg': {
    width: '100%',
    overflow: 'hidden',
    paddingTop: '56.25%',
    position: 'relative',
    backgroundImage: `url(${DefaultImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
});

export const TechTalksCard = ({ url, title }: TechTalksProps) => {
  const containerClass = url ? 'iframContainer' : 'defaultImg';
  return (
    <KbTechTalksCard>
      <Box className={containerClass}>{url && <iframe className='ifram' src={url} frameBorder='0' />}</Box>
      <Typography variant='body1'>{title}</Typography>
    </KbTechTalksCard>
  );
};

import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const KbHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  marginBottom: '2.5rem',
  fontWeight: 500,
  textTransform: 'capitalize',
}));

interface HeaderProps {
  align?: any;
  title: string;
  variant?: any;
  className?: string;
}

export const Heading = ({ align, title, variant, className }: HeaderProps) => {
  return (
    <KbHeading align={align} variant={variant} className={className}>
      {title}
    </KbHeading>
  );
};

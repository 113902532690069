import React, { useContext, useState } from 'react';
import { UserAgentApplication } from 'msal';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { UserContext, UserContextType } from '../../Context/UserContext';
import msalLogo from '../../assets/images/msalLogo.png';

import LoginImg from '../../assets/images/persona.png';
import AccessDeniedImg from '../../assets/images/access-denied.png';
import Logo from '../../assets/images/talentica_logo.svg';
import {
  AccessDeniedCard,
  AccessDeniedText,
  AccessDeniedTitle,
  Card,
  Form,
  FormHeading,
  Illustration,
  KBSButtuon,
  PageHeading,
} from './styles';

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useContext<UserContextType>(UserContext);
  const [notTalenticaUser, setNotTalenticaUser] = useState<boolean>();

  const msalInstance = new UserAgentApplication({
    auth: {
      clientId: process.env.REACT_APP_APPID ? process.env.REACT_APP_APPID : '',
      redirectUri: process.env.REACT_APP_REDIRECT_KNOWLEDGEBASE_URL
        ? process.env.REACT_APP_REDIRECT_KNOWLEDGEBASE_URL
        : '',
      authority: process.env.REACT_APP_AUTHORITY ? process.env.REACT_APP_AUTHORITY : '',
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  });

  const loginWithOutlook = async () => {
    localStorage.clear();
    try {
      await msalInstance.loginPopup({
        scopes: process.env.REACT_APP_SCOPES ? JSON.parse(process.env.REACT_APP_SCOPES) : '',
        prompt: 'select_account',
      });
      getUserProfile();
    } catch (err) {
      localStorage.clear();
    }
  };

  const getUserProfile = async () => {
    try {
      const accessToken = await msalInstance.acquireTokenSilent({
        scopes: process.env.REACT_APP_SCOPES ? JSON.parse(process.env.REACT_APP_SCOPES) : '',
      });
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken.accessToken);
        sessionStorage.clear();
        setUser(true);
        navigate('/');
      } else {
        navigate('/login');
        setUser(false);
      }
    } catch (err) {
      setUser(false);
      localStorage.clear();
      sessionStorage.clear();
      navigate('/login');
    }
  };

  return (
    <>
      {notTalenticaUser ? (
        <Box flexGrow={1} display={'flex'} alignItems={'center'} justifyContent={'center'} height='100%'>
          <AccessDeniedCard>
            <img src={AccessDeniedImg} alt='access-denied' />
            <AccessDeniedTitle title='Access Denied' />
            <AccessDeniedText title='Sorry, looks like you do not have the permission to access this page' />
          </AccessDeniedCard>
        </Box>
      ) : (
        <Box flexGrow={1} display={'flex'} height='100%'>
          <Illustration flexBasis={'50%'}>
            <PageHeading title='Welcome to Talentica Knowledgebase!' />
            <img src={LoginImg} alt='login' />
          </Illustration>
          <Form flexBasis={'50%'}>
            <Card>
              <img width={150} height={50} src={Logo} alt='talentica' />
              <FormHeading title='Login to continue' />
              <KBSButtuon onClick={loginWithOutlook}>
                <img src={msalLogo} /> Sign in with Microsoft
              </KBSButtuon>
            </Card>
          </Form>
        </Box>
      )}
    </>
  );
};

export default Login;

import { InputLabel, MenuItem, Select, SelectChangeEvent, useTheme, Typography, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';

import Icon from '../../Icons/icons';

import { KbDropdown, StyledMenu, ButtonStyle, cardStyle, chipStyle } from './style';
interface DropdownProps {
  size?: 'medium' | 'small';
  label?: string;
  value?: string;
  id?: string;
  itemText?: string;
}

interface CustomizedDropdownProps {
  label: string;
  type: string;
  icon?: string;
  onClick?: () => void;
  sorting?: (type: boolean, key: string) => void;
  expertiseLevel?: string;
  selectExpertiseLevel?: (exp: [{ title: string; value: string }]) => void;
  clearExpertiseLevel?: () => void;
  subCategories?: any[];
  handleSubsCategories?: (subs: any) => void;
  handlePopularity?: (popularity: boolean) => void;
}

export const Dropdown = ({ size, value, label, id, itemText }: DropdownProps) => {
  const [dropdownState, setDropdownState] = React.useState('');
  const handleDropdownClick = (event: SelectChangeEvent) => {
    setDropdownState(event.target.value as string);
  };
  return (
    <KbDropdown size={size}>
      <InputLabel>{label}</InputLabel>
      <Select id={id} value={value} label={label} onChange={handleDropdownClick}>
        <MenuItem value={itemText}>{itemText}</MenuItem>
      </Select>
    </KbDropdown>
  );
};

export function SortDropdown({
  label,
  icon,
  type,
  sorting,
  selectExpertiseLevel,
  clearExpertiseLevel,
  subCategories,
  handleSubsCategories,
  handlePopularity,
}: CustomizedDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  const cardFooter = {
    textAlign: 'end',
    mt: 0.75,
    '& button': {
      textTransform: 'capitalize',
      fontSize: '12px',
      fontWeight: 400,
    },
  };
  const [selection, setSelection] = useState<any>([]);
  const [subsSelection, setSubsSelection] = useState<any>([]);
  const [popularity, setPopularity] = useState<boolean>(false);

  const handleExpertiseSelection = (expertise: { title: string; value: string }) => {
    const arr: any = [...selection];
    if (arr.find((exp: any) => exp === expertise?.value)) {
      const index = arr.indexOf(expertise?.value);
      arr.splice(index, 1);
    } else {
      arr.push(expertise?.value);
    }
    setSelection(arr);
    selectExpertiseLevel && selectExpertiseLevel(arr);
  };
  const handleSubsSelection = (subs: string) => {
    const subCat = [...subsSelection];
    if (subCat?.find((exp: any) => exp === subs)) {
      const index = subCat.indexOf(subs);
      subCat.splice(index, 1);
    } else {
      subCat?.push(subs);
    }
    setSubsSelection(subCat);
    handleSubsCategories && handleSubsCategories(subCat);
  };

  const btnActive = open || selection?.length || subsSelection?.length || popularity;
  return (
    <div>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        sx={ButtonStyle(btnActive)}
      >
        {icon && (
          <Icon
            kind={icon}
            size={15}
            color={btnActive ? theme.palette.primary.main : theme.palette.text.secondary}
            style={{ marginRight: '0.2rem' }}
          />
        )}
        {label}
      </Button>
      {type === 'sort' ? (
        <StyledMenu
          id='demo-customized-menu'
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {[
            { sortBy: 'name', ascending: true, title: 'Name - A to Z' },
            { sortBy: 'name', ascending: false, title: 'Name - Z to A' },
            { sortBy: 'createdAt', ascending: true, title: 'Date - New to Old' },
            { sortBy: 'createdAt', ascending: false, title: 'Date - Old to New' },
          ].map((item, index) => (
            <MenuItem
              key={item?.title}
              onClick={() => {
                sorting && sorting(item?.ascending, item?.sortBy);
                handleClose();
              }}
              disableRipple
            >
              {item?.title}
            </MenuItem>
          ))}
        </StyledMenu>
      ) : (
        <StyledMenu
          id='demo-customized-menu'
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <Box component={'li'} sx={cardStyle}>
            <Typography component={'h6'} sx={{ mb: 1 }}>
              Expertise Level
            </Typography>
            {[
              { title: 'Low Team Expertise', value: 'zero,one' },
              { title: 'Medium Team Expertise', value: 'two,three' },
              { title: 'High Team Expertise', value: 'four,five' },
              { title: 'Need to Learn', value: 'true' },
            ].map((expertise, index) => (
              <Chip
                label={expertise.title}
                key={expertise.title}
                size='small'
                sx={chipStyle}
                style={{
                  backgroundColor: selection.find((expertiseSelected: any) => expertiseSelected === expertise?.value)
                    ? theme.palette.primary.main
                    : '#F2F2F2',
                  color: selection.find((expertiseSelected: any) => expertiseSelected === expertise?.value)
                    ? theme.palette.primary.contrastText
                    : '#666',
                }}
                onClick={() => handleExpertiseSelection(expertise)}
              />
            ))}
            {subCategories?.length ? (
              <Typography component={'h6'} sx={{ mb: 1 }}>
                Sub Category
              </Typography>
            ) : null}

            {subCategories?.map(({ attributes: { name } }: any) => {
              return (
                <>
                  <Chip
                    label={name}
                    key={name}
                    size='small'
                    sx={chipStyle}
                    style={{
                      backgroundColor: subsSelection.find((subcat: any) => subcat === name)
                        ? theme.palette.primary.main
                        : '#F2F2F2',
                      color: subsSelection.find((subcat: any) => subcat === name)
                        ? theme.palette.primary.contrastText
                        : '#666',
                    }}
                    onClick={() => handleSubsSelection(name)}
                  />
                  {/* <span>{subs?.attributes?.name}</span>); */}
                </>
              );
            })}
            <Typography component={'h6'} sx={{ mb: 1 }}>
              Popularity
            </Typography>
            <Chip
              label={'Recent popular'}
              size='small'
              sx={chipStyle}
              style={{
                backgroundColor: popularity ? theme.palette.primary.main : '#F2F2F2',
                color: popularity ? theme.palette.primary.contrastText : '#666',
              }}
              onClick={() => {
                setPopularity(!popularity);
                handlePopularity && handlePopularity(!popularity);
              }}
            />
            <Divider sx={{ my: 1, borderColor: '#eee' }} />
            <Box sx={cardFooter}>
              {/* <Button
                size='small'
                onClick={() => {
                  clearExpertiseLevel && clearExpertiseLevel();
                  setSelection([]);
                  setSubsSelection([]);
                  setPopularity(false);
                  handleClose();
                }}
                sx={{
                  color: '#666',
                  mr: '0.625rem',
                }}
              >
                Clear Selection
              </Button> */}
              <Button
                variant='contained'
                size='small'
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
              {/* <Button
                variant='contained'
                size='small'
                onClick={() => {
                  selectExpertiseLevel && selectExpertiseLevel(selection);
                  handleSubsCategories && handleSubsCategories(subsSelection);
                  handlePopularity && handlePopularity(popularity);
                  handleClose();
                }}
              >
                Apply
              </Button> */}
            </Box>
          </Box>
        </StyledMenu>
      )}
    </div>
  );
}

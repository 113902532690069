import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import RootApp from './Containers/App';
import { useState } from 'react';
import { UserContext } from './Context/UserContext';
import { UpdatesContext } from './Context/NewUpdatesContext';
// import { jwtDecode } from 'jwt-decode';

export const muiCache = createCache({
  key: 'kb',
});

declare global {
  interface Window {
    dataLayer: any;
  }
}

function App() {
  const loginUser = localStorage.getItem('accessToken') as string;
  const [user, setUser] = useState<boolean | null>(loginUser ? true : null);
  const [homePageData, setHomePageData] = useState<any>(null);
  const [newUpdates, setNewUpdates] = useState<any[]>([]);
  const [newUpdatesModal, setNewUpdatesModal] = useState<boolean>(false);

  return (
    <CacheProvider value={muiCache}>
      <Router>
        <UserContext.Provider value={{ user, setUser, homePageData, setHomePageData }}>
          <UpdatesContext.Provider value={{ newUpdates, setNewUpdates, newUpdatesModal, setNewUpdatesModal }}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <RootApp />
            </ThemeProvider>
          </UpdatesContext.Provider>
        </UserContext.Provider>
      </Router>
    </CacheProvider>
  );
}

export default App;

import { Avatar, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import { RightSideContent, RightSideHeader } from '../../Components/molecules/RightSideBar';
import { IExperts } from '../../types/Experts';
import { styled } from '@mui/material/styles';
import React from 'react';

const KbExperts = styled(List)(({ theme }) => ({
  paddingTop: 0,
  '& .MuiTypography-body1': {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.14,
    textTransform: 'capitalize',
  },
  '& .MuiTypography-body2': {
    fontSize: '1rem',
    lineHeight: 1.12,
    fontWeight: 'normal',
  },
  '& .MuiDivider-root': {
    borderColor: '##BEBEBE',
  },
}));

interface ExpertsInterface {
  data: any;
  tabName: any;
}
function generateContributorsList(articles: IExperts[]) {
  const contributors: IExperts[] = [];
  if (articles) {
    articles.forEach((article: any) => {
      const contributor = article.attributes.contributors?.data;
      if (contributor && contributor.id && !contributors.some((c) => c.id === contributor.id)) {
        contributors.push(contributor);
      }
    });
  }
  return contributors;
}

export const Experts = ({ data, tabName }: ExpertsInterface) => {
  const contributorsList = generateContributorsList(data[0]?.attributes?.contents?.data || []);
  return (
    <>
      <RightSideHeader>{tabName}</RightSideHeader>
      <RightSideContent>
        <KbExperts>
          {contributorsList?.length ? (
            contributorsList.map((item: IExperts, i) => {
              const {
                attributes: { username, email },
              } = item;

              return (
                <React.Fragment key={username + i}>
                  <ListItem alignItems='flex-start' disableGutters={true}>
                    <ListItemText primary={username} secondary={email} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })
          ) : (
            <Typography variant='h3' className='noDataText'>
              Sorry, we couldn't find any results
            </Typography>
          )}
        </KbExperts>
      </RightSideContent>
    </>
  );
};

import moment from 'moment';
export function formatBlogType(type?: string) {
  switch (type) {
    case 'hard-problem':
      return { title: 'Hard Problem', kind: 'hard-problem', color: '#4556AF' };
    case 'blogs-and-tl-articles':
      return { title: 'Blogs & TL Articles', kind: 'blog', color: '#4556AF' };
    case 'innovations':
      return { title: 'Innovations', kind: 'hard-problem', color: '#4556AF' };
    default:
      return {};
  }
}

export function categoriesIcon(type?: string) {
  switch (type) {
    case 'Languages':
      return 'languages';
    case 'Web Frameworks':
      return 'web-frameworks';
    case 'Database':
      return 'database';
    case 'Architecture & Deisgn':
      return 'architecture-design';
    case 'BigData':
      return 'big-data';
    case 'DevOps':
      return 'dev-ops';
    case 'UX':
      return 'ux';
    case 'QA':
      return 'qa';
    default:
      return 'languages';
  }
}

export const leftMenuData = [
  {
    menuTitle: 'About',
    content: [
      {
        subMenu: 'Brief Details',
      },
      {
        subMenu: 'Best Practices',
      },
    ],
  },
  // {
  //   menuTitle: 'Reference Impl',
  //   content: [
  //     {
  //       subMenu: 'How To Setup',
  //     },
  //     {
  //       subMenu: 'Code Starter Kit',
  //     },
  //   ],
  // },
  {
    menuTitle: 'Our Experience',
    content: [
      {
        subMenu: 'Our Thoughts',
      },
      {
        subMenu: 'Hard Problems',
      },
      {
        subMenu: 'Innovations',
      },
      {
        subMenu: 'Blogs & TL Articles',
      },
      {
        subMenu: 'Common Mistakes',
      },
      {
        subMenu: 'Tech Talks',
      },
    ],
  },
  {
    menuTitle: 'Contact',
    content: [
      {
        subMenu: 'Experts',
      },
      {
        subMenu: 'Team',
      },
    ],
  },
];

export function convertExpertiseToNumber(value: string) {
  switch (value) {
    case 'one':
      return 1;
    case 'two':
      return 2;
    case 'three':
      return 3;
    case 'four':
      return 4;
    case 'five':
      return 5;
    case 'six':
      return 6;
    default:
      return 0;
  }
}
export const differentCalculation = (date1: string, date2: string) => {
  let dt1 = new Date(date1);
  let dt2 = new Date(date2);
  const differentCount: any = Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
  return differentCount;
};

export const formateDate = (date: string): string => {
  return moment(date).format('MM-DD-YYYY');
};

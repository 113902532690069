import { Breadcrumbs, Box, styled } from '@mui/material';
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useContext } from 'react';
import { UpdatesContext } from '../../../Context/NewUpdatesContext';
const Breadcrumb = () => {
  const location: any = useLocation();
  const { newUpdates, setNewUpdatesModal } = useContext(UpdatesContext);
  const handleOpen = () => setNewUpdatesModal(true);
  const Badge = styled(Box)(({ theme }) => ({
    padding: '0.2rem 0.40rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '1rem',
    fontSize: '0.85rem',
    marginLeft: '0.5rem',
    cursor: 'pointer',
  }));
  return (
    <Breadcrumbs
      separator={<>&nbsp;{'>'}&nbsp;</>}
      aria-label='breadcrumb'
      sx={{
        '& li': {
          display: 'inline-flex',
        },
      }}
    >
      {location.pathname !== '/' &&
        location.pathname !== '/dashboard' &&
        location.pathname.split('/').map((item: any, i: number) => {
          return (
            <Box display={'flex'} key={i}>
              <Link
                key={i}
                to={item === '' ? '/' : '/' + item}
                // style={{ display: 'inline-flex' }}
                className={location.pathname.split('/').length - 1 === i ? 'inactive' : 'active'}
              >
                {item === '' ? 'Home' : decodeURIComponent(item).split('-').join(' ')}
              </Link>
              {newUpdates?.count > 0 && i === location.pathname.split('/').length - 1 && (
                <Badge onClick={handleOpen}>{newUpdates.count}</Badge>
              )}
            </Box>
          );
        })}
      {/* {newUpdates?.count && <Badge onClick={handleOpen}>{newUpdates?.count}</Badge>} */}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
